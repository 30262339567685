/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-10-11 09:59:07
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:34
 */
import {
  MUTATIONS_PARK3D__PARKING_GROUPTRANSITRECORD,
  MUTATIONS_PARK3D__PARKING_MONTHLYCARDTRANSITRECORD,
  MUTATIONS_PARK3D__PARKING_TEMPTRANSITRECORD
} from '../mutations_type'

const carDataRecord = {
  state: () => {
    return {
      groupTransitRecord: [
        {
          id: '',
          plateNo: '',
          vehiclePicUrl: '',
          setPosition: '',
          entranceName: '',
          orientation: '',
          transitTime: ''
        }
      ],
      monthlyCardTransitRecord: [
        {
          id: '',
          plateNo: '',
          vehiclePicUrl: '',
          setPosition: '',
          entranceName: '',
          orientation: '',
          transitTime: ''
        }
      ],
      tempTransitRecord: [
        {
          id: '',
          plateNo: '',
          vehiclePicUrl: '',
          setPosition: '',
          entranceName: '',
          orientation: '',
          transitTime: ''
        }
      ]
    }
  },
  mutations: {
    [MUTATIONS_PARK3D__PARKING_GROUPTRANSITRECORD](state, val) {
      state.groupTransitRecord = val
    },
    [MUTATIONS_PARK3D__PARKING_MONTHLYCARDTRANSITRECORD](state, val) {
      state.monthlyCardTransitRecord = val
    },
    [MUTATIONS_PARK3D__PARKING_TEMPTRANSITRECORD](state, val) {
      state.tempTransitRecord = val
    }
  },
  actions: {
    fn_api_get_park_parking_group_transitRecord({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARKING_GROUPTRANSITRECORD, data)
    },
    fn_api_get_park_parking_monthlyCard_transitRecord({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARKING_MONTHLYCARDTRANSITRECORD, data)
    },
    fn_api_get_park_parking_temp_TransitRecord({ commit }, data) {
      commit(MUTATIONS_PARK3D__PARKING_TEMPTRANSITRECORD, data)
    }
  }
}
export default carDataRecord
